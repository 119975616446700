<template>
  <div class="video-item" @click="viewVideo(item.id)">
    <div class="d-flex">
      <div class="pr-0">
        <b-aspect aspect="16:9" class="cover">
          <img :src="item.img_cover_small" alt="cover" />
          <div class="duration">{{ item.duration }}</div>
        </b-aspect>
      </div>
      <div class="pl-0">
        <div class="detail">
          <div class="title" :title="item.name">
            <p>{{ (item.name) }}</p></div>
          <div class="author">{{ 'WYA Yoga' }}</div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  props: {
    item: Object,
    mode: String
  },
  name: 'VideoItem',
  methods: {
    viewVideo (id) {
      if (this.mode === 'exclusive') {
        this.$router.push({ name: 'exClusiveCenterVideo', params: { id: id } })
      } else {
        this.$router.push({ name: 'video', params: { id: id } })
      }
    }
  }
}
</script>

<style scoped>
  .video-item {
    cursor: pointer;
    display: flex;
    margin-bottom: 20px;
  }
  .video-item .cover {
    position: relative;
    width: 168px !important;
    overflow: hidden;
    background: #000;
  }
  .video-item:hover .cover img {
    opacity: .7;
    transform: scale(1.25, 1.25);

  }
  .video-item .cover img {
    position: relative;
    width: 100%;
    /* transition: transform ease .25s; */
  }
  .video-item .cover .duration {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 3px 6px;
    line-height: 1.2em;
    background-color: rgba(0,0,0, .65);
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
  }
   .video-item .detail {
    padding-left: 10px;
    font-size: 14px;
    color: rgba(0,0,0, .45);
  }
  .video-item .detail .title {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .video-item .detail .author{
    margin-top: 5px;
    font-size: 13px;
    line-height: 1em;
  }
  .video-item .detail .date {
    display: inline-block;
    padding: 3px 6px;
    line-height: 1em;
    background-color: #31C2F2;
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
  }
  .video-item .detail .data.hot {
    background-color: #ff6060;
  }

</style>
